import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async getList(filter)
    {
        const response = await httpService.get(`unitconversion`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(response.data.data[i]);
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`unitconversion/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        // response.data = this._toObject(response.data);
        return response;
    },

    async create(model) {
        const response = await httpService.post('unitconversion', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async update(model, id) {
        const response = await httpService.update('unitconversion', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('unitconversion', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async upsertBulk(model) {
        const response = await httpService.post('unitconversion/upsertbulk', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        return response;
    },

    default() {
        return {
            id: 0,
            fromUnitId: 0,
            toUnitId: 0,
            coefisien: 0,
        };
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.fromUnitId = jsonData.fromUnitId;
        result.toUnitId = jsonData.toUnitId;
        result.coefisien = jsonData.coefisien;
        return result;
    }
});